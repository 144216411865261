footer {
  background-color: var(--secondary__color);
  color: var(--text_light__color);
  .footer__grid {
    display: grid;
    gap: 1.5rem;
    grid-template-rows: 1fr;
    padding: 1rem;
    @include desktop {
      grid-template-rows: 1fr;
      grid-template-columns: repeat(3, 1fr);
    }
    .footer__section {
      .section__header {
        align-items: center;
        display: flex;
        gap: 1rem;
        margin: 2rem 0;
      }
      .section__badge {
        align-items: center;
        background-color: var(--primary__color);
        border-radius: 50%;
        display: flex;
        font-size: 1.5rem;
        height: 3rem;
        justify-content: center;
        text-align: center;
        width: 3rem;
      }
      .section__body {
        margin: 1rem 0 0 0;
      }
      .section__location {
        a {
            color: var(--text_light__color);
            text-decoration: none;
        }
        h4 {
          margin: 1rem 0 .75rem 0;
          text-transform: uppercase;
        }
        p {
          line-height: 1.5rem;
        }
      }
      .section__assistant {
        h4 {
          margin: 1rem 0 .75rem 0;
          text-transform: uppercase;
        }
        p {
          line-height: 1.5rem;
          margin-bottom: 1rem;
        }
        a.assistant__link {
          color: var(--text_light__color);
          line-height: 1.5rem;
          text-decoration: none;
        }
      }
      .section__twocolumns {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        h4 {
          margin: 0 0 .75rem 0;
          text-transform: uppercase;
        }
        nav {
          ul {
            li {
              list-style: none;
              a {
                color: var(--accent__color);
                line-height: 1.5rem;
                text-decoration: none;
                &.active {
                  color: var(--text_light__color);
                  font-weight: bold;
                }
              }
            }
          }
        }
        .section__tworows {
          display: flex;
          flex-direction: column;
          .section__social {
            align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            a {
              margin: 1rem 0;
              width: 50%;
            }
            img {
              width: 100%;
            }
          }
          .section__datafiscal {
            padding: 2rem;
            img {
              object-fit: contain;
              width: 100%;
            }
          }
        }
      }
    }
  }
}